/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */

import React, { lazy, Suspense } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./Footer.css";

export default function Footer({ data }) {
  return (
    <>
      <div>
        <Container className="footer">
            <Row className="justify-content-center text-center">
                <Col sm={12} md={12} xl={8} lg={10}>
                    <p className="opportunity">
                    {HTMLReactParser(data.footer.opportunity)}
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="text-center mb-4">
                    <Button className="register-btn"><a href="/#contact-us-form">{HTMLReactParser(data.footer.button)}</a></Button>
                </Col>
            </Row>
            <Row className="justify-content-center text-center">
                <Col sm={12} md={12} xl={8} lg={10}>
                    <p className="disclaimer-footer">
                        {HTMLReactParser(data.footer.disclaimer1)}
                    </p>
                    <p className="disclaimer-footer">
                        {HTMLReactParser(data.footer.disclaimer2)}
                    </p>
                </Col>
            </Row>
            </Container>
      </div>
    </>
  );
}

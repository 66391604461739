/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Button, Container, Navbar } from "react-bootstrap";
import splLogo from "./spl-logo.png";
// import { useLocation } from "react-router-dom";
import axios from "axios";
import { capitalizeFirstLetter, isMobileOrTablet } from "../Utils/index.js";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";
import "./headerNav.css";

export default function HeaderNav() {
  const [navbar, setNavbar] = useState(false);  
  const isMobileOrTabletDevice = isMobileOrTablet();
  const imgCDNURL = process.env.REACT_APP_CDN_SPL;
  // const location = useLocation();

  let prevScrollPos = window.scrollY;

  window.onscroll = function () {
    const currentScrollPos = window.scrollY;
    const Navbar = document.getElementById("Navbar");

    if (currentScrollPos === 0) {
      Navbar.classList.remove("navbarTransition");
      setNavbar(false);
    } else {
      if (prevScrollPos > currentScrollPos) {
        // Perform your desired actions for scrolling up here
        setNavbar(true);
      } else if (prevScrollPos < currentScrollPos) {
        // Perform your desired actions for scrolling down here
        if (!isMobileOrTabletDevice) {
          setNavbar(true);
        } else {
          setNavbar(false);
          Navbar.classList.add("navbarTransition");          
        }
      }
    }
    prevScrollPos = currentScrollPos;
  };

  const handleMenuClick = () => {
    setNavbar(false);
    };
  
  function scrollToSection1() {
    const sectionPosition = document.getElementById("contact-wrapper").offsetTop;
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
    setNavbar(false);
  }

  return (
    <>
      <div>
        <Navbar
          id="Navbar"
          collapseOnSelect
          expand="lg"
          fixed="top"
          bg="dark"
          variant="dark"
          className={navbar ? "navbar active" : "navbar"}
        >
          <Container>
            <Navbar.Brand className="d-flex">
              <img src={splLogo} className="spl-logo" alt="SPL" />
              <UserWayAccessibilityMenu color="white" width={30} height={30} />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./WhoWillBenefit.css";

export default function WhoWillBenefit({ data }) {
  const imgCDNURL = process.env.REACT_APP_CDN_SPL;

  return (
    <>
      <div>
        <Container className="mb-5 pb-3">
            <Row className="align-items-center benefit-row">
                <Col sm={12} md={12} xl={4} lg={5} className="text-center">
                <img src={imgCDNURL + "who-will-benefit-image.webp"} className="benefit-img"/>
                </Col>
                <Col sm={12} md={12} xl={8} lg={7} className="benefit-text">
                <h4>{HTMLReactParser(data.whoWillBenefit.heading)}</h4>
                <div>
                {HTMLReactParser(data.whoWillBenefit.points)}
                </div>
                </Col>
            </Row>
            </Container>
      </div>
    </>
  );
}

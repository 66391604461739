/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, { lazy, Suspense, useState, useEffect } from "react";
import "@fontsource/poppins";
import "../src/App.css";
import Banner from "./components/Banner/Banner";
import TimeVenue from "./components/Time-Venue/TimeVenue";
import WhatYouLearn from "./components/WhatYouLearn/WhatYouLearn";
import WhoWillBenefit from "./components/WhoWillBenefit/WhoWillBenefit";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/HeaderNav";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import MediaQuery from "react-responsive";
import axios from "axios";

function App() {
  const isProduction = process.env.NODE_ENV === "production";
  const appVersion = appversion.version;
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = Date.now();
        const url = `${
          process.env.REACT_APP_CDN_SPL + "SPL-Force.json"
        }?timestamp=${timestamp}`;
        const response = await axios.get(url);
        // console.log(response);
        setData(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="mainPage">
        <>
          {data && (
            <>
            <Navbar fetchpriority="high" data={data} />
              <Banner fetchpriority="high" data={data} />
              <TimeVenue data={data} />
              <WhatYouLearn data={data} />
              <WhoWillBenefit data={data} />
              <Footer data={data} />
            </>
          )}
        </>
      </div>
    </CacheBuster>
  );
}

export default App;

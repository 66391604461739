/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./Banner.css";

const Form = lazy(() => import("../SignUpForm/SignUpForm"));

export default function Banner({ data }) {
  const imgCDNURL = process.env.REACT_APP_CDN_SPL;

  return (
    <>
      <div className="banner">
        <Container className="bannercontainer">
          <Row>
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="banner-first-section mb-5 pb-3">
                <p className="desktop text-center">
                  {HTMLReactParser(data.banner.headerPointsDesktop)}
                </p>
                <p className="mobile text-center">
                  {HTMLReactParser(data.banner.headerPointsMobile)}
                </p>
              </div>
              <div className="banner-second-section text-center">
                <h2>{HTMLReactParser(data.banner.subHeading)}</h2>
                <img src={imgCDNURL + "certification.webp"} className="certification" />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col sm={12} md={12} xl={5} lg={7}>
              <div className="banner-third-section d-flex">
                <div className="orange-box desktop"></div>
                <p className="banner-paragraph">{HTMLReactParser(data.banner.paragraph)}</p>
              </div>
            </Col>
            <Col sm={12} md={12} xl={3} lg={5}>
              <div className="banner-fourth-section">
                <img
                  src={imgCDNURL + "sam-profile.webp"}
                  className="sam-img" alt="Sam Chauhan"
                  id="contact-us-form"
                />
                <h4>{HTMLReactParser(data.banner.samChauhan)}</h4>
              </div>
            </Col>
            <Col sm={12} md={12} xl={4} lg={12}>
  <div className="form-container">
    <Suspense fallback={<div>Loading...</div>}>
      <Form />
    </Suspense>
  </div>
</Col>

          </Row>
        </Container>
      </div>
    </>
  );
}
